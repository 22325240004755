<template>
  <a-row :gutter="24" type="flex">
    <a-col :span="24" class="mb-24">
      <a-card
        :bordered="false"
        class="header-solid h-full"
        :bodyStyle="{ padding: 0 }"
      >
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">
                Feedback List
                <a-spin v-if="confirmLoading" />
              </h5>
            </a-col>
          </a-row>
        </template>
        <a-table
          :columns="columnData"
          :data-source="featureList"
          :pagination="false"
        >
        </a-table>

        <div class="pagination">
          <a-pagination
            v-model:current="currentPage"
            :total="pagination"
            :page-size="10"
            :hideOnSinglePage="true"
            @change="onPageChange"
          />
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { sendRequest } from "@/http/axios.method";
import { message } from "ant-design-vue";

const COLUMN_DATA = [
  {
    title: "Sl",
    dataIndex: "key",
    scopedSlots: { customRender: "key" },
    width: 10,
  },
  {
    title: "Feedback Type",
    dataIndex: "name",
    width: 10,
  },
  {
    title: "Feedback",
    dataIndex: "title",
    width: 200,
  },
];

export default {
  name: "Feature",
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      columnData: COLUMN_DATA,
      feedbacks: [],
      projectHeaderBtns: "all",
      currentPage: 1,
      pagination: 0,
      confirmLoading: false,
    };
  },
  created() {
    this.getFeatureList();
  },
  methods: {
    onPageChange() {
      this.getFeatureList();
    },
    getFeatureList() {
      this.confirmLoading = true;
      let data = {
        page: this.currentPage,
      };
      sendRequest("get", "admin/get-feedback-list", data, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data.feedbacks.data;
            this.feedbacks = data.map(function (content, label) {
              return {
                key: label + 1,
                id: content.id,
                name: content.feedback_type,
                title: content.feedback,
              };
            });
            this.pagination = response.data.feedbacks.total;
            this.currentPage = response.data.feedbacks.current_page;
            if (this.responseMessage > "") {
              message.success(this.responseMessage);
            }
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
  computed: {
    featureList: function () {
      return this.feedbacks;
    },
    responseMessage() {
      const message = this.$store.state.message;
      this.$store.commit("resetMessage");
      return message;
    },
  },
};
</script>
